import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Table, Button, Col, Row, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import Header from "../Layout/Header/Header";
import BotComment from "../CommentsList.jsx/BotComment";
import SobreTurno from "../Apointment/SobreTurno";
import exportFromJSON from "export-from-json";
import Expediente from "../Formulario/Expediente";
import ReactLoading from 'react-loading'
import dayjs from "dayjs";
import "../../index.css";
import './List.css'
import '../Formulario/Expediente.css'
import { TableVirtuoso } from 'react-virtuoso'
// import DeleteUser from "../Delete/DeleteUser";

function List(props) {
  const { isAuth, user1 } = useContext(AuthContext);
  const [data1, setData1] = useState([]);
  const [dataxls, setDataxls] = useState([]);
  const [order, setOrder] = useState("first_name");
  const [list, setList] = useState(props.lista);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  let [loading, setLoading] = useState(false)
  const URL_GET_ADMINS = `${process.env.REACT_APP_API}${props.lista}/${user1.id}`;
  const excludeColumns = ["_id", "is_active", "createdAt", "password", "updatedAt",]; // excluye datos del arreglo del filtro
  let [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let [citasUsuario, setCitasUsuario] = useState([])

  // handle change event of search input
  const handleChange = (value) => {
    setSearchText(value);
    filterData(value);
  };

  useEffect(() => {
    setLoading(true)
    if (user1.id != undefined) {
      axios
        .get(URL_GET_ADMINS, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then(
          // eslint-disable-next-line
          (data) => (setUsers(data.data), setData1(data.data), setSearchText(""), setLoading(false))
        )
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [user1]);

  useEffect(() => {
    verify();
    // eslint-disable-next-line
  }, [data1]);

  const verify = () => {
    if (list === "admins") {
      setList("admins");
    }
    if (list === "doctors") {
      setList("doctors");
    }
    if (list === "secres") {
      setList("secres");
    }
    if (list === "usuarios") {
      setList("usuarios");
    }
  };

  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData1(users);
    else {
      const filteredData = users.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : String(item[key]).toLowerCase().includes(lowercasedValue)
        );
      });
      setData1(filteredData);
    }
  };

  const sortJSON = (json, key, orden) => {
    // eslint-disable-next-line
    return json.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  };

  sortJSON(data1, order, "asc");

  const data = dataxls;
  const fileName = "ReporteContactos";
  const exportType = "csv";

  const xls = () => {
    exportFromJSON({ data, fileName, exportType });
  };

  useEffect(() => {
    let list1 = list;
    if (list1 === "admins") {
      let fname = data1.map((v) => v.nombre);
      let lname = data1.map((v) => v.apellido);
      let email = data1.map((v) => v.email);
      let tel = data1.map((v) => v.celular);

      let datos = [];

      for (var i = 0; i < fname.length; i++) {
        datos.push({
          Nombre: fname[i],
          Apellido: lname[i],
          Email: email[i],
          Telefono: tel[i],
        });
        setDataxls(datos);
      }
    } else if (list1 === "doctors") {
      let fname = data1.map((v) => v.nombre);
      let lname = data1.map((v) => v.apellido);
      let country = data1.map((v) => v.country);
      let specialty = data1.map((v) => v.specialty);
      let email = data1.map((v) => v.email);
      let tel = data1.map((v) => v.celular);

      let datos = [];

      for (let i = 0; i < fname.length; i++) {
        datos.push({
          Nombre: fname[i],
          Apellido: lname[i],
          Pais: country[i],
          Especialidad: specialty[i],
          Email: email[i],
          Telefono: tel[i],
        });
        setDataxls(datos);
      }
    }
    else if (list1 === "secres") {
      let fname = data1.map((v) => v.nombre);
      let lname = data1.map((v) => v.apellido);
      let email = data1.map((v) => v.email);
      let tel = data1.map((v) => v.celular);

      let datos = [];

      for (let i = 0; i < fname.length; i++) {
        datos.push({
          Nombre: fname[i],
          Apellido: lname[i],
          Email: email[i],
          Telefono: tel[i],
        });
        setDataxls(datos);
      }
    } else if (list1 === "usuarios") {
      let fname = data1.map((v) => v.nombre);
      let lname = data1.map((v) => v.apellido);
      let age = data1.map((v) => v.age);
      let country = data1.map((v) => v.country);
      let comunity = data1.map((v) => v.comunity);
      let email = data1.map((v) => v.email);
      let tel = data1.map((v) => v.celular);

      let datos = [];

      for (let i = 0; i < fname.length; i++) {
        datos.push({
          Nombre: fname[i],
          Apellido: lname[i],
          Edad: age[i],
          Pais: country[i],
          Comunidad: comunity[i],
          Email: email[i],
          Telefono: tel[i],
        });
        setDataxls(datos);
      }
    }
    // eslint-disable-next-line
  }, [data1]);

  return (
    <>
      <Modal size="xl" show={show}>
        <Modal.Header>
          <Modal.Title>Historial de turnos</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Fecha</th>
                      <th scope="col">Hora</th>
                      <th scope="col">Motivo</th>
                      <th scope="col">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      citasUsuario.map((cita, i) => (
                        <tr key={i}>
                          <th scope="row">{dayjs(cita.date).format("DD/MM/YYYY")}</th>
                          <td>{cita.time}</td>
                          <td>{cita.note}</td>
                          <td>{cita.asistio == "si" ? "Realizada" : cita.asistio == "no" ? "Cancelada" : "Pendiente"}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer><Button variant="secondary" onClick={handleClose}>Cerrar</Button></Modal.Footer>

      </Modal>

      {isAuth ? (
        <div className="noprint">
          <Header />
          <br />
          <Row>
            <Col>
              <h1 className="margin">{props.titulo}</h1>
            </Col>
            {user1.role == "admin" ?
              list != "usuarios" ?
                <Col>
                  <Button
                    href={list == "admins" ? "/signupadmin" : list == "doctors" ? "/signupdoctor" : list == "secres" ? "/signupsecre" : null}
                    variant="success"
                    className="float"
                  >
                    Nuevo
                  </Button>
                </Col>
                : null
              : null}
          </Row>
          {user1.role == "admin" ?
            <div className="float">
              <Button
                variant="outline-success rounded-circle "
                onClick={xls}
                className="margin"
              >
                {" "}
                <i className="far fa-file-excel"></i>
              </Button>
            </div>
            : null}
          <input
            className="w3-input w3-border w3-animate-input buscador"
            type="text"
            placeholder="Busqueda"
            value={searchText}
            onChange={(e) => handleChange(e.target.value)}
          ></input>
          {loading ?
            <center>
              <div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div>
            </center>
            :
            <TableVirtuoso
              style={{ height: "60vh" }}
              components={{ Table: ({ style, ...props }) => <Table hover {...props} style={{ ...style, width: "100%" }} /> }}
              data={data1}
              fixedHeaderContent={() => (
                <tr>
                  <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Nombre</th>
                  <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Apellido</th>
                  {list == "doctors" ?
                    <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Especialidad</th>
                    : null}
                  <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Email</th>
                  {list == "doctors" || list == "usuarios" || list == "secres" ?
                    user1.role == "admin" || user1.role == "secre" ?
                      <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Dni</th>
                      : null
                    : null}
                  <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Telefono</th>
                  {user1.role != "user" && list == "usuarios" ?
                    <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Expediente</th>
                    : null}
                  {user1.role != "user" && list == "usuarios" ?
                    <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Historial</th>
                    : null}
                  {user1.role == "doctor" || user1.role == "secre" ?
                    list == "usuarios" ?
                      <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Sobre Turno</th>
                      : null
                    : null}
                  {list == "admins" ? null :
                    <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Comentarios</th>
                  }
                </tr>
              )}
              itemContent={(index, user) => (
                <>
                  <td style={{ textAlign: 'center', minWidth: '20vh', maxWidth: '20vh' }}>{user.nombre.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                  <td style={{ textAlign: 'center', minWidth: '20vh', maxWidth: '20vh' }}>{user.apellido.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                  {list == "doctors" ?
                    <td style={{ textAlign: 'center' }}>{user.especialidad.map((item) => item + " ")}</td>
                    : null}
                  <td style={{ textAlign: 'center', minWidth: '40vh', maxWidth: '40vh' }}>{user.email}</td>
                  {list == "doctors" || list == "usuarios" || list == "secres" ?
                    user1.role == "admin" || user1.role == "secre" ?
                      <td style={{ textAlign: 'center', minWidth: '15vh', maxWidth: '15vh' }}>{user.dni}</td>
                      : null
                    : null}
                  <td style={{ textAlign: 'center', minWidth: '15vh', maxWidth: '15vh' }}>{user.celular}</td>
                  {user1.role != "user" && list == "usuarios" ?
                    <td style={{ textAlign: 'center' }}><Expediente user={user} /></td>
                    : null}
                  {user1.role != "user" && list == "usuarios" ?
                    <td style={{ textAlign: 'center' }}>
                      <div className="centrado">
                        <OverlayTrigger
                          placement="top" // La posición del tooltip (puede ser "top", "bottom", "left", "right")
                          overlay={<Tooltip id="button-tooltip">Historial de turnos</Tooltip>}
                        >
                          <Button onClick={() => {
                            handleShow()
                            axios.get(`${process.env.REACT_APP_API}schedulesbyuser/${user1.id}/${user._id}`,
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                                },
                              })
                              .then((res) => {
                                setCitasUsuario(res.data)
                              })
                              .catch((err) => {
                                console.log(err)
                              })
                          }}><i class="fa-solid fa-calendar-days"></i></Button>
                        </OverlayTrigger>
                      </div>
                    </td>
                    : null
                  }
                  {user1.role == "doctor" || user1.role == "secre" ?
                    list == "usuarios" ?
                      <td style={{ textAlign: 'center' }}>
                        <SobreTurno
                          id={user._id}
                          nombre={user.nombre.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                          apellido={user.apellido.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                        />
                      </td>
                      : null
                    : null}
                  {list == "admins" ? null :
                    <td style={{ textAlign: 'center' }}>
                      <BotComment
                        id={user._id}
                        first_name={user.nombre.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                        last_name={user.apellido.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                      />
                    </td>
                  }
                </>
              )
              }
            />

          }
        </div>
      ) : undefined}
    </>
  );
}

export default List;