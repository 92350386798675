import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Button, Container, Table } from "react-bootstrap";
// import BList from "./BList";
import Header from "../Layout/Header/Header";
// import Footer from "../Layout/Footer/Footer";
import DeleteBlockHour from "../Delete/DeleteBlockHour";
import Note from "../Note/Note";
import axios from "axios";
import "../../index.css";
// import encode from "nodejs-base64-encode";
// import Swal from "sweetalert2";
// import img from "../../contexts/ImgContext";
import exportFromJSON from "export-from-json";
import "jspdf-autotable";
// import { jsPDF } from "jspdf";
import BlockApointment from "../Apointment/BlockApointment";
// import useWindowWidthAndHeight from "../useWindowWidthAndHeight/useWindowWidthAndHeight";
import dayjs from "dayjs";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import DaysOff from "../DaysOff/DaysOff";
import ReactLoading from 'react-loading'
import { TableVirtuoso } from 'react-virtuoso'
registerLocale("es", es);

function BlockList() {
  const { isAuth, user1 } = useContext(AuthContext);
  // eslint-disable-next-line
  // const [width, height] = useWindowWidthAndHeight();
  let [loading, setLoading] = useState(false)
  const [schedule, setSchedule] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2] = useState([]);
  const [order, setOrder] = useState("date");
  const [searchText, setSearchText] = useState("Horas por dia");
  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo del filtro
  const URL_GET_SCHEDULE = `${process.env.REACT_APP_API}${user1.role === "doctor" ? "schedulesbydoctor" : user1.role === "admin" ? "schedules" : null}/${user1.id}/${user1.role === "doctor" ? user1.id : user1.role === "admin" ? "" : null}`;
  // const [attachment, setAttachment] = useState("");
  // const URLSENDREPORT = `${process.env.REACT_APP_API}sendreport/`;
  // const [email, setEmail] = useState("Correo electronico");
  const [dataxls, setDataxls] = useState([]);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    setLoading(true)
    if (user1.role != undefined) {
      axios
        .get(URL_GET_SCHEDULE, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        // eslint-disable-next-line
        .then((data) => (setSchedule(data.data), setData1(data.data), setLoading(false)))
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [user1]);

  const toFind = (selectedDay) => {
    filterData(`${dayjs(selectedDay).format("YYYY-MM-DD")}T`);
    setSearchText(`${dayjs(selectedDay).format("YYYY/MM/DD")}T`);
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData1(schedule);
    else {
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : String(item[key]).toLowerCase().includes(lowercasedValue)
        );
      });
      setData1(filteredData);
    }
  };

  const Todas = () => {
    setData1(schedule);
    setSearchText(null);
    setSearchText("Horas por dia");
  };

  const sortJSON = (json, key, orden) => {
    // eslint-disable-next-line
    return json.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  };

  sortJSON(data1, order, "asc");

  /// DESDE AQUI EMPIEZA LOS REPORTES PDF
  // useEffect(() => {
  //   const doc = new jsPDF();
  //   doc.autoTable({
  //     margin: { top: 50 },
  //   });
  //   doc.text("Horas Libres", 20, 30);
  //   doc.addImage(img, "JPEG", 160, 15, 20, 20);
  //   doc.autoTable({ html: "#table" });
  //   var att = doc.output("arraybuffer");
  //   var base64File = encode.encode(att, "base64");
  //   setAttachment(base64File);
  // }, [email]);

  // const sendReport = () => {
  //   axios
  //     .post(
  //       URLSENDREPORT,
  //       {
  //         email,
  //         attachment,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //         },
  //       }
  //     )
  //     .then(() => {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Listo!",
  //         confirmButtonText: `Ok`,
  //         timer: 1000,
  //         timerProgressBar: true,
  //         allowEscapeKey: true,
  //       }).then(() => {
  //         window.location.reload();
  //       });
  //     })
  //     .catch((error) => {
  //       let message = error.response.data.message;
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Lo sentimos esta acción no se pudo completar " + message,
  //         allowEscapeKey: true,
  //       });
  //       console.log(error);
  //     });
  // };

  // const downloadPdf = () => {
  //   const doc = new jsPDF();
  //   const text = "Horas Libres";
  //   doc.autoTable({
  //     margin: { top: 50 },
  //   });
  //   doc.text(text, 20, 30);
  //   doc.addImage(img, "JPEG", 160, 15, 20, 20);
  //   doc.autoTable({ html: "#table" });
  //   doc.save("HorasLibres.pdf");
  // };

  const data = dataxls;
  const fileName = "HorasLibres";
  const exportType = "csv";

  const xls = () => {
    exportFromJSON({ data, fileName, exportType });
  };

  useEffect(() => {
    // eslint-disable-next-line
    data1.map((date) => {
      const fecha = new Date(date.date).valueOf();
      const now = Date.now();
      if (fecha >= now) {
        data2.push(date);
      }
    });

    let inf = data2.filter((v) => v.type === false);
    let date = inf.map((v) => `${dayjs(v.date).format("DD-MM-YYYY")}`);
    let time = inf.map((v) => v.time);
    let docfna = inf.map((v) => v.profesional[0].nombre);
    let doclna = inf.map((v) => v.profesional[0].apellido);

    let datos = [];

    for (var i = 0; i < date.length; i++) {
      datos.push({
        Fecha: date[i],
        Hora: time[i],
        NombreDr: docfna[i],
        ApellidoDr: doclna[i],
      });
      setDataxls(datos);
    }
    // eslint-disable-next-line
  }, [data1]);
  /// AQUI TERMINA LOS REPORTES PDF

  return (
    <>
      {isAuth ? (
        <>
          {user1.role == "admin" || user1.role == "doctor" || user1.role === "secre" ?
            <>
              <Header />

              <br />

              <div className="row">
                <div style={{ zIndex: 1000 }} className="col"><DatePicker className="picker" calendarStartDay={0} locale="es" todayButton="Hoy" placeholderText="Selecciona la Fecha" selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => toFind(date)} /></div>

                <div className="col"><Button variant="success" onClick={Todas}>Ver todas las horas libres</Button></div>

                <div className="col"><BlockApointment /></div>

                <div className="col"><DaysOff /></div>
                {/* <div className="row"> */}
                {/* ///DESDE AQUI EMPIEZA LOS REPORTES PDF */}
                {/* <div className="col"> */}
                {/* <Button
              style={{ marginRight: "5px" }}
                variant="outline-danger rounded-circle"
                onClick={downloadPdf}
                className="marginWebCirculos"
              >
                <i className="fas fa-file-pdf"></i>
              </Button>
              <Button
              style={{ marginRight: "5px" }}
                variant="outline-primary rounded-circle"
                onClick={handleShow}
                className="marginWebCirculos"
              >
                <i className="fas fa-envelope-open-text"></i>
              </Button>
              <Modal show={show} size="sm" onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    <h6>Exportar</h6>{" "}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          name="email"
                          id="exampleEmail"
                          placeholder="Correo electronico"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                  style={{ marginRight: "5px" }}
                    type="submit"
                    onClick={() => {
                      sendReport();
                    }}
                    className="btn btn-primary rounded-pill"
                  >
                    Enviar
                  </Button>
                </Modal.Footer>
              </Modal> */}
                <div className="col">
                  <Button
                    variant="outline-success rounded-circle"
                    onClick={xls}
                  >
                    {" "}
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
                {/* </div> */}
                {/* ///AQUI TERMINA LOS REPORTES PDF */}
                {/* </div> */}



              </div>

              <div className="seccion1">
                <Container className="themed-container" fluid={true}>
                  <div className="filtros">
                    {user1.role === "doctor" ?
                      <>
                        <div style={{ marginBottom: "10px" }}>
                        </div>
                      </>
                      : null}
                  </div>

                  {loading ?
                    <center>
                      <div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div>
                    </center>
                    :
                    <TableVirtuoso
                      style={{ height: "70vh", zIndex: 0 }}
                      components={{ Table: ({ style, ...props }) => <Table hover {...props} style={{ ...style, width: "100%" }} /> }}
                      data={data1.filter((date) => { const fecha = new Date(date.date).valueOf(); const now = Date.now(); return date.type === false && fecha >= now })}
                      fixedHeaderContent={() => (
                        <tr>
                          <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Fecha</th>
                          <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Hora</th>
                          {user1.role == "doctor" ? null :
                            <>
                              <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Apellido Dr.</th>
                              <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Nombre Dr.</th>
                            </>}
                          <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Nota</th>
                          <th style={{ textAlign: 'center', backgroundColor: 'white' }}>Cancelar</th>
                        </tr>
                      )}
                      itemContent={(index, date) => (
                        <>
                          <td style={{ textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{`${dayjs(date.date).format("DD-MM-YYYY")}`}</td>
                          <td style={{ textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{date.time}</td>
                          {user1.role == "doctor" ? null :
                            <>
                              <td style={{ textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{date.profesional[0].nombre}</td>
                              <td style={{ textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{date.profesional[0].apellido}</td>
                            </>
                          }
                          <td style={{ textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{<Note note={date.note} />}</td>
                          <td style={{ textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{<DeleteBlockHour id={date._id} datee={date.date} timee={date.time} />}</td>
                        </>
                      )}

                    />
                  }
                </Container>
              </div>
              {/* <Footer /> */}
            </>
            : null}
        </>
      ) : undefined}
    </>
  );
}

export default BlockList;