import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Table, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import dayjs from "dayjs";
import DeleteContact from "../Delete/DeleteContact";
import "../../index.css";
import exportFromJSON from "export-from-json";
import "jspdf-autotable";
import Header from "../Layout/Header/Header";
import ReactLoading from 'react-loading'
import { TableVirtuoso } from 'react-virtuoso'

function AdminList() {
  const { isAuth, user1 } = useContext(AuthContext);
  const [data1, setData1] = useState([]);
  const [dataxls, setDataxls] = useState([]);
  const [order, setOrder] = useState("first_name");
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const URL_GET_CONTACTS = `${process.env.REACT_APP_API}contacts`;
  let [loading, setLoading] = useState(false)
  const excludeColumns = [
    "_id",
    "is_active",
    "createdAt",
    "password",
    "updatedAt",
  ]; // excluye datos del arreglo del filtro

  // handle change event of search input
  const handleChange = (value) => {
    setSearchText(value);
    filterData(value);
  };

  useEffect(() => {
    setLoading(true)
    axios
      .get(URL_GET_CONTACTS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(
        // eslint-disable-next-line
        (data) => (setUsers(data.data), setData1(data.data), setSearchText(""), setLoading(false))
      )
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData1(users);
    else {
      const filteredData = users.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : String(item[key]).toLowerCase().includes(lowercasedValue)
        );
      });
      setData1(filteredData);
    }
  };

  const sortJSON = (json, key, orden) => {
    // eslint-disable-next-line
    return json.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  };

  sortJSON(data1, order, "asc");

  const data = dataxls;
  const fileName = "ReporteContactos";
  const exportType = "csv";

  const xls = () => {
    exportFromJSON({ data, fileName, exportType });
  };

  useEffect(() => {
    let fname = data1.map((v) => v.first_name);
    let lname = data1.map((v) => v.last_name);
    let email = data1.map((v) => v.email);
    let tel = data1.map((v) => v.tel);
    let datos = [];
    for (var i = 0; i < fname.length; i++) {
      datos.push({
        Nombre: fname[i],
        Apellido: lname[i],
        Email: email[i],
        Telefono: tel[i],
      });
      setDataxls(datos);
    }
  }, [data1]);

  return (
    <>
      {isAuth ? (
        user1.role === "admin" || user1.role === "secre" ? (
          <>
            <Header />
            <Row>
              <Col>
                <h1 className="margin">Contactos</h1>
              </Col>
            </Row>
            <div className="float">
              <Button
                style={{ marginRight: "5px" }}
                variant="outline-success rounded-circle"
                onClick={xls}
              >
                {" "}
                <i className="far fa-file-excel"></i>
              </Button>
            </div>
            <input
              className="w3-input w3-border w3-animate-input buscador"
              type="text"
              placeholder="Busqueda"
              value={searchText}
              onChange={(e) => handleChange(e.target.value)}
            ></input>
            <TableVirtuoso
              style={{ height: "60vh" }}
              data={data1}
              components={{ Table: ({ style, ...props }) => <Table hover {...props} style={{ ...style, width: '100%' }} /> }}
              fixedHeaderContent={() => (
                <tr>
                  <th style={{ textAlign: 'center', backgroundColor: 'white', maxWidth: '200px', minWidth: '200px', position: 'sticky', left: 0, zIndex: 1 }}>Fecha</th>
                  <th style={{ textAlign: 'center', backgroundColor: 'white', maxWidth: '200px', minWidth: '200px' }}>Nombre</th>
                  <th style={{ textAlign: 'center', backgroundColor: 'white', maxWidth: '200px', minWidth: '200px' }}>Email</th>
                  <th style={{ textAlign: 'center', backgroundColor: 'white', maxWidth: '200px', minWidth: '200px' }}>Telefono</th>
                  <th style={{ textAlign: 'center', backgroundColor: 'white', maxWidth: '200px', minWidth: '200px' }}>Comentario</th>
                  <th style={{ textAlign: 'center', backgroundColor: 'white', maxWidth: '200px', minWidth: '200px' }}>Eliminar</th>
                </tr>
              )}
              itemContent={(index, user) => (
                <>
                  <td style={{ textAlign: 'center', maxWidth: '200px', minWidth: '200px', position: 'sticky', left: 0 }}>{dayjs(user.createdAt).format('DD/MM/YY')}</td>
                  <td style={{ textAlign: 'center', maxWidth: '200px', minWidth: '200px' }}>{user.name}</td>
                  <td style={{ textAlign: 'center', maxWidth: '200px', minWidth: '200px' }}>{user.email}</td>
                  <td style={{ textAlign: 'center', maxWidth: '200px', minWidth: '200px' }}>{user.tel}</td>
                  <td style={{ textAlign: 'center', maxWidth: '200px', minWidth: '200px' }}>{user.note}</td>
                  <td style={{ textAlign: 'center', maxWidth: '200px', minWidth: '200px' }}><DeleteContact id={user._id} /></td>
                </>
              )}
            />
            {loading ?
              <center>
                <div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div>
              </center>
              : null}
          </>
        ) : undefined
      ) : undefined}
    </>
  );
}

export default AdminList;
